<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Febrero 2024                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-main style="padding: 0 0 !important;">
    
    <!-- Contenedor con la información de las solicitudes del afiliado -->
    <v-container v-if="mostrarSolicitudes" class="container">
      <v-row justify="space-between" class="ma-0">
        <v-col cols="12" sm="12" md="12" style="max-height: 75vh; overflow-y: auto; padding-bottom: 2rem">
          <v-card v-for="item in listaDeSolicitudes" :key="item.id" class="mb-3 elevation-2">
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="12" md="6">
                  <!-- Número, tipo, estado y fecha de la solicitud -->
                  <v-row align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="5" align="left">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Solicitud: </span>
                        <span> {{ item.idSolicitud === undefined ? item.idSote : item.idSolicitud }} </span>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="7" align="left">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Tipo de solicitud: </span>
                        <span> {{ item.tipoSolicitud !== 'HOME_DELIVERY' ? 'Entrega programada' : 'Entrega a domicilio' }} </span>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="5" align="left">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Estado: </span>
                        <span :class="obtenerClaseEstado(item.estadoSolicitudEntrega)"> {{ traducirEstado(item.estadoSolicitudEntrega) }} </span>
                        <v-btn v-if="item.estadoSolicitudEntrega === 'REFUSED' && item.visibleCount !== 0" icon @mousedown.prevent="dialogoRechazado = true">
                          <v-icon color="#0d47a1">info</v-icon>
                        </v-btn>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="7" align="left" class="mb-1">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Fecha de solicitud: </span>
                        <span> {{ formatearFecha(item.fechaSolicitud) }}</span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Valor de la cuota moderadora -->
                  <v-row v-if="item.tipoSolicitud === 'SCHEDULED_DELIVERY' && (item.estadoSolicitudEntrega === 'INVOICE' || item.estadoSolicitudEntrega === 'DISPACHED')" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-2">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Valor de la cuota: </span>
                        <span> {{ parseFloat(item.valorCuota).toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }) }} </span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Valor del domicilio -->
                  <v-row v-if="item.tipoSolicitud === 'HOME_DELIVERY' && item.estadoSolicitudEntrega === 'DISPACHED'" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-2">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Valor del domicilio: </span>
                        <span> {{ parseFloat(item.valorDomicilio).toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }) }} </span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Total a Pagar -->
                  <v-row v-if="item.tipoSolicitud === 'HOME_DELIVERY' && item.estadoSolicitudEntrega === 'DISPACHED'" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-2">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Total a pagar: </span>
                        <span> {{ (parseFloat(item.valorCuota) + parseFloat(item.valorDomicilio)).toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }) }} </span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Dirección de entrega a domicilio -->
                  <v-row v-if="item.tipoSolicitud === 'HOME_DELIVERY' && item.direccionEntrega !== ''" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-2">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Dirección de domicilio: </span>
                        <span>{{ consultarTipoDirecion(item) }}</span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Dirección de entrega programada-->
                  <v-row v-if="item.tipoSolicitud === 'SCHEDULED_DELIVERY' && item.estadoSolicitudEntrega === 'RESOLVED' && item.bodegas.bodegasId.codigoBodega !== null && item.bodegas.bodegasId.codigoBodega !== 0" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-2">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Su fórmula será entregada en: </span>
                        <span> {{ item.bodegaConsultada }} {{ item.direccionBodega }} </span>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Notificación -->
                  <v-row v-if="(item.estadoSolicitudEntrega === 'CLOSE' || item.estadoSolicitudEntrega === 'REFUSED' || item.estadoSolicitudEntrega === 'NOVELTY') && item.descripcionMotivo" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-0">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Notificación: </span>
                        <span style="color: #0d47a1;">{{ item.descripcionMotivo }}</span>
                        <v-tooltip v-if="item.adjunto !== null && item.adjunto !== ''" right color="error">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="error" v-bind="attrs" v-on="on" @mousedown.prevent="verAdjuntoNotificacion(item)">
                              <v-icon color="red">picture_as_pdf</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver notificación</span>
                        </v-tooltip>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- Observación complementaria en el rechazo de la solicitud -->
                  <v-row v-if="(item.estadoSolicitudEntrega === 'CLOSE' || item.estadoSolicitudEntrega === 'REFUSED') && item.observacionMotivo" align="center" no-gutters class="encabezadoCard">
                    <v-col cols="12" md="12" align="left" class="mb-0">
                      <v-card tile color="lighten-3" class="elevation-0">
                        <span class="font-weight-black">Observación: </span>
                        <span style="color: #0d47a1;">
                          {{ item.observacionMotivo.length > 50 ? item.observacionMotivo.substr(0, 47) + '...' : item.observacionMotivo }}
                        </span>
                        <v-btn v-if="item.observacionMotivo.length > 50" text small class="ver-observacion"
                          @click="mensajeObservacion = item.observacionMotivo; dialogMensajeObservacion = true;">
                          ver más
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- Tabla con los archivos subidos -->
                <v-col cols="12" md="6">
                  <v-row no-gutters justify="space-around">
                    <v-col cols="12" md="12">
                      <v-simple-table dense class="table">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th v-if="item.registroAfiliado.documentoDigital !== null && item.registroAfiliado.documentoDigital !== ''" width="25%" class="text-center black--text font-weight-black">
                                DOCUMENTO
                              </th>
                              <th v-if="item.nombreFormula && item.nombreFormula.trim() !== ''" width="25%" class="text-center black--text font-weight-black">
                                FÓRMULA
                              </th>
                              <th v-if="item.nombreAutorizacion && item.nombreAutorizacion.trim() !== ''" width="25%" class="text-center black--text font-weight-black">
                                AUTORIZACIÓN
                              </th>
                              <th v-if="item.nombreComprobante && item.nombreComprobante.trim() !== ''" width="25%" class="text-center black--text font-weight-black">
                                PAGO
                              </th>
                            </tr>
                          </thead>
                          <tbody class="tbody">
                            <tr>
                              <td v-if="item.registroAfiliado.documentoDigital !== '' && item.registroAfiliado.documentoDigital !== null" class="text-center" width="25%">
                                <v-btn icon v-if="item.registroAfiliado.documentoDigital !== '' && item.registroAfiliado.documentoDigital !== null" @click="verDocumento(item)">
                                  <v-icon color="red">picture_as_pdf</v-icon>
                                </v-btn>
                                <v-icon v-if="item.registroAfiliado.documentoDigital.trim() !== '' && item.registroAfiliado.documentoDigital !== null && item.estadoSolicitudEntrega === 'REFUSED'" :color="item.documentoCorrecto !== true ? 'red' : 'success'">
                                  {{ item.documentoCorrecto !== true ? 'close' : 'check' }}
                                </v-icon>
                              </td>
                              <td v-if="item.nombreFormula && item.nombreFormula.trim() !== ''" class="text-center" width="25%">
                                <v-btn icon v-if="item.nombreFormula !== ''" @click="verFormula(item)">
                                  <v-icon color="red">picture_as_pdf</v-icon>
                                </v-btn>
                                <v-icon v-if="item.nombreFormula !== '' && item.estadoSolicitudEntrega === 'REFUSED'" :color="item.formulaCorrecta !== true ? 'red' : 'success'">
                                  {{ item.formulaCorrecta !== true ? 'close' : 'check' }}
                                </v-icon>
                              </td>
                              <td v-if="item.nombreAutorizacion && item.nombreAutorizacion.trim() !== ''" class="text-center" width="25%">
                                <v-btn icon v-if="item.nombreAutorizacion !== ''" @click="verAutorizacion(item)">
                                  <v-icon color="red">picture_as_pdf</v-icon>
                                </v-btn>
                                <v-icon v-if="item.nombreAutorizacion !== '' && item.estadoSolicitudEntrega === 'REFUSED'" :color="item.autorizacionCorrecta !== true ? 'red' : 'success'">
                                  {{ item.autorizacionCorrecta !== true ? 'close' : 'check' }}
                                </v-icon>
                              </td>
                              <td v-if="item.nombreComprobante && item.nombreComprobante.trim() !== ''" class="text-center" width="25%">
                                <v-btn icon v-if="item.nombreComprobante !== ''" @click="verComprobantePago(item)">
                                  <v-icon color="red">picture_as_pdf</v-icon>
                                </v-btn>
                                <v-icon v-if="item.nombreComprobante !== '' && item.estadoSolicitudEntrega === 'REFUSED'" :color="item.comprobantePago !== true ? 'red' : 'success'">
                                  {{ item.comprobantePago !== true ? 'close' : 'check' }}
                                </v-icon>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- Carga del documento faltante -->
              <v-row v-if="item.idMotivo && item.idMotivo === 18 && item.estadoSolicitudEntrega === 'REFUSED'" align="center" no-gutters class="encabezadoCard">
                <v-col cols="12" md="11" style="margin: 0;" class="pa-1">
                  <v-row class="ma-0">
                    <v-col cols="12" md="3" class="d-flex align-center justify-start pa-0">
                      <span class="font-weight-black" style="color: black;">Adjunte la autorización médica</span>
                    </v-col>
                    <v-col cols="12" md="9" style="height: 4rem" class="pl-0">
                      <validation-provider v-slot="{ errors }" class="mr-4">
                        <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," ref="documentoFaltanteFile" prefix="Seleccionar archivo" 
                          prepend-icon :clearable="true" :truncate-length="sizeText" outlined dense :error-messages="formatoIncorrecto.documentoFaltante || errors" 
                          @change="cargarDocumentoFaltante('documentoFaltante', item)" @click:clear="borrarDocumento('documentoFaltante')">
                        </v-file-input>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="1" style="margin: 0;" class="d-flex align-center pa-1">
                  <v-row>
                    <v-col v-if="documentos.documentoFaltante !== '' && documentos.documentoFaltante !== null" cols="12" md="12" class="text-center d-flex pa-0">
                      <v-tooltip v-if="!screenSmaller" left color="#0d47a1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="borrarDocumentoIcono('documentoFaltante')">
                            <v-icon medium color="error">delete</v-icon>
                          </v-btn>
                          <v-btn icon @mousedown.prevent="abrirDocumento('documentoFaltante')" v-bind="attrs" v-on="on">
                            <v-icon medium color="#0d47a1">visibility</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver autorización médica</span>
                      </v-tooltip>
                      <div v-else>
                        <v-btn icon @click="borrarDocumentoIcono('documentoFaltante')">
                          <v-icon medium color="error">delete</v-icon>
                        </v-btn>
                        <v-btn icon @click="abrirDocumento('documentoFaltante')">
                          <v-icon medium color="#0d47a1">visibility</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!--Contenedor para cuando no hay solicitudes -->
    <v-container v-if="mostrarSolicitudes === false" style="height: 75vh;" class="d-flex justify-center align-center">
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-row align="center" no-gutters>
            <v-col cols="12" md="12" sm="12" class="d-flex justify-center align-center">
              <v-icon large color="gray">feedback</v-icon>
            </v-col>
          </v-row>
          <v-row align="center" no-gutters>
            <v-col cols="12" md="12" sm="12" class="d-flex justify-center align-center">
              <span style="color: gray"> No has realizado solicitudes.</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- Paginación-->
    <v-footer v-if="mostrarSolicitudes" class="pb-6 pagination-container">
      <v-row class="pagination d-flex justify-center pr-2">
        <v-col cols="12" md="4" class="d-flex justify-center pa-0 pr-1">
          <span class="pagination-text pt-1 pr-2">{{ inicioItem }} - {{ finItem }} de {{ totalPaginas }}</span>
          <v-btn small @click="irPrimeraPagina" :disabled="pagina === 1" icon>
            <v-icon>first_page</v-icon>
          </v-btn>
          <v-btn  small @click="anteriorPagina" :disabled="pagina === 1" icon>
            <v-icon>chevron_left</v-icon>
          </v-btn>
          <span class="pagination-text pt-1">{{ pagina }}</span>
          <v-btn  small @click="siguientePagina" :disabled="pagina * itemsPorPagina >= totalPaginas" icon>
            <v-icon>chevron_right</v-icon>
          </v-btn>
          <v-btn small @click="irUltimaPagina" :disabled="pagina * itemsPorPagina >= totalPaginas" icon>
            <v-icon>last_page</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
    
    <!-- Dialogo para ver el documento de identidad y remplazarlo si fue rechazado en la solicitud -->
    <v-dialog v-model="mostarDocumento" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title class="d-flex justify-center" style="color: #0d47a1">{{ modalTitulo }}</v-card-title>
        <v-card-text>
          <pdf :src="pdfBase64" :page="1"  :key="pdfBase64" width="100%"></pdf>
          <div v-if="mostrarBotones">
            <span style="color: #0d47a1;">Reemplazar documento de identidad</span>
            <validation-provider v-slot="{ errors }">
              <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," ref="documentoIdentidadFile" prefix="Seleccionar archivo" prepend-icon :clearable="true" :truncate-length="sizeText"
                outlined dense :error-messages="formatoIncorrecto.documento || errors" @change="cargarDocumento('documento')" @click:clear="borrarDocumento('documento')">
              </v-file-input>
            </validation-provider>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end mr-3">
          <v-btn class="white--text mb-4" color="red" rounded @click="cerrarDocumento()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Dialogo para ver la fórmula médica y remplazarla si fue rechazada en la solicitud -->
    <v-dialog v-model="mostarFormula" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title class="d-flex justify-center" style="color: #0d47a1">{{ modalTitulo }}</v-card-title>
        <v-card-text>
          <pdf :src="pdfBase64" :page="1"  :key="pdfBase64" width="100%"></pdf>
          <div v-if="mostrarBotones">
            <span style="color: #0d47a1;">Reemplazar fórmula médica</span>
            <validation-provider v-slot="{ errors }">
              <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," ref="formulaMedicaFile" prefix="Seleccionar archivo" prepend-icon :clearable="true" :truncate-length="sizeText"
                outlined dense :error-messages="formatoIncorrecto.formulaMedica || errors" @change="cargarDocumento('formulaMedica')" @click:clear="borrarDocumento('formulaMedica')">
              </v-file-input>
            </validation-provider>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end mr-3">
          <v-btn class="white--text mb-4" color="red" rounded @click="cerrarDocumento()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Dialogo para ver la autorización médica y remplazarla si fue rechazada en la solicitud -->
    <v-dialog v-model="mostarAutorizacion" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title class="d-flex justify-center" style="color: #0d47a1">{{ modalTitulo }}</v-card-title>
        <v-card-text>
          <pdf :src="pdfBase64" :page="1"  :key="pdfBase64" width="100%"></pdf>
          <div v-if="mostrarBotones">
            <span style="color: #0d47a1;">Reemplazar autorización médica</span>
            <validation-provider v-slot="{ errors }">
              <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," ref="autorizacionMedicaFile" prefix="Seleccionar archivo" prepend-icon :clearable="true" :truncate-length="sizeText"
                outlined dense :error-messages="formatoIncorrecto.autorizacionMedica || errors" @change="cargarDocumento('autorizacionMedica')" @click:clear="borrarDocumento('autorizacionMedica')">
              </v-file-input>
            </validation-provider>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end mr-3">
          <v-btn class="white--text mb-4" color="red" rounded @click="cerrarDocumento()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
 
    <!-- Dialogo para ver el comprobante de pago y remplazarlo si fue rechazado en la solicitud -->
    <v-dialog v-model="mostrarComprobante" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title class="d-flex justify-center" style="color: #0d47a1">{{ modalTitulo }}</v-card-title>
        <v-card-text>
          <pdf :src="pdfBase64" :page="1"  :key="pdfBase64" width="100%"></pdf>
          <div v-if="mostrarBotones">
            <span style="color: #0d47a1;">Reemplazar comprobante de pago</span>
            <validation-provider v-slot="{ errors }">
              <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," ref="comprobanteFile" prefix="Seleccionar archivo" prepend-icon :clearable="true" :truncate-length="sizeText"
                outlined dense :error-messages="formatoIncorrecto.comprobante || errors" @change="cargarDocumento('comprobante')" @click:clear="borrarDocumento('comprobante')">
              </v-file-input>
            </validation-provider>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end mr-3">
          <v-btn class="white--text mb-4" color="red" rounded @click="cerrarDocumento()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Diálogo de para ver el documento subido que remplazará el documento rechazado -->
    <v-dialog v-model="mostrarModal" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title>{{ dialogTitulo }}</v-card-title>
        <v-card-text>
          <template v-if="componenteDocumento === 'imagen'">
            <img v-if="extension !== 'tiff'" :src="documentoSeleccionado" width="100%">
            <canvas v-else ref="tiffCanvas" width="100%"></canvas>
          </template>
          <template v-else>
            <pdf :src="documentoSeleccionado" :page="1" :show-all="false" width="100%"></pdf>
          </template>
          <!-- <pdf :src="documentoSeleccionado" :page="1" :show-all="false" width="100%"></pdf> -->
        </v-card-text>
        
        <v-card-actions v-if="!documentoReemplazado" class="justify-end">
          <v-btn class="boton-actualizar mb-4" @click="cerrarModal">Cancelar</v-btn>
          <v-btn class="boton-actualizar mb-4 me-2" @click="confirmarDocumento">Confirmar</v-btn>
        </v-card-actions>
        <v-card-actions v-else class="justify-end mr-3">
          <v-btn class="white--text mb-4" color="red" rounded @click="cerrarDocumento()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Mensaje de alerta para cuando el cambio del documento rechazado es exitoso -->
    <template>
      <div v-if="alertaReemplazo" class="overlay">
        <v-alert v-model="alertaReemplazo" class="notificationValidation" persistent transition="dialog-bottom-transition">
          <v-icon class="d-flex justify-center" color="white" large>task_alt</v-icon>
          <span class="d-flex justify-center">{{ mensajeReemplazo }}</span>
        </v-alert>
      </div>
    </template>

    <!-- Dialogo para ver una nota cuando tenga una solicitud rechazada -->
    <v-dialog v-model="dialogoRechazado" max-width="36rem" scrollable persistent>
      <v-card>
        <v-card-title class="ma-0 pa-0 d-flex justify-end" style="background-color: #0d47a1">
          <v-icon class="ma-1" @click="dialogoRechazado = false" color="white">close</v-icon>
        </v-card-title>
        <v-card-text class="mt-3">
          <span style="color: black">
            <strong>Nota:</strong> Debe corregir el documento que fue rechazado, estará marcado con una <v-icon color="red">close</v-icon>
            <br>
            Para corregirlo debe seleccionar el <v-icon color="red">picture_as_pdf</v-icon> y subir el nuevo documento.
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <!-- Mensaje de alerta para cuando hay una solicitud en proceso -->
    <template>
      <div v-if="validateProgress" class="overlay">
        <v-alert class="notificationValidation" persistent transition="dialog-bottom-transition">
          <v-icon class="d-flex justify-center rotate-animation" color="white" large>rotate_right</v-icon>
          <span class="d-flex justify-center">Solicitud en proceso</span>
        </v-alert>
      </div>
    </template>

    <!-- Contenedor para mostrar los mensajes de error -->
    <div v-if="mostrarNotificacion" :class="['notificacion', tipoNotificacion]">
      <span><v-icon :color="tipoNotificacionColor" :class="iconoNotificacionClase" class="rotate-animation-notification size">{{ iconoNotificacion }}</v-icon></span>
      <span>{{ mensajeNotificacion }}</span>
    </div>

    <!-- Dialogo para ver documento adjunto en la notificación -->
    <v-dialog v-model="dialogoAdjuntoNotificacion" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title class="d-flex justify-center" style="color: #0d47a1">Notificación</v-card-title>
        <v-card-text>
          <pdf :src="adjuntoPdf" :page="1"  :key="adjuntoPdf" width="100%"></pdf>
        </v-card-text>
        <v-card-actions class="justify-end mr-3">
          <v-btn class="white--text mb-4" color="red" rounded @click="cerrarAdjunto()">Cerrar</v-btn>
          <v-btn class="white--text mb-4" color="primary" rounded @click="descargarAdjunto()">Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
    <!-- Campo para mostrar la observación en un dialogo cuando es muy larga -->
    <v-dialog v-model="dialogMensajeObservacion" max-width="32rem" scrollable>
      <v-card>
        <v-card-text class="pa-4">
          {{ mensajeObservacion }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pt-0">
          <v-btn color="error" text @click=" dialogMensajeObservacion = false">
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { ValidationProvider } from 'vee-validate';
import { mapMutations, mapState } from 'vuex';
import { Role } from '@/router/role.js';
import "jquery-ui/ui/widgets/resizable";
import $ from "jquery";
import moment from 'moment';
import pdf from 'vue-pdf';
import Tiff from 'tiff.js';

export default {
  name: 'Historial',
  components: {
    pdf,
    ValidationProvider,
  },

  data() {
    return {
      roles: Role,
      userRoles: {},
      usuario: '',
      eliminado: false,
      loading: false,
      headersRegistros: [
        { text: "NÚMERO", sortable: false, width: "2rem" },
        { text: "TIPO SOLICITUD", sortable: false, width: "11rem" },
        { text: "DOCUMENTO", align: "center", sortable: false, width: "7rem" },
        { text: "FÓRMULA MÉDICA", align: "center", sortable: false, width: "7rem" },
        { text: "AUTORIZACIÓN MÉDICA", align: "center", sortable: false, width: "7rem" },
        { text: "ESTADO", sortable: false, width: "5rem" },
        { text: "FECHA", sortable: false, width: "8rem" },
      ],
      listaDeSolicitudes: [],
      pagina: 1,
      itemsPorPagina: 10,
      totalPaginas: 0,
      footerPropslistaMedicos: {
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Items por página:',
        'show-current-page': true,
        'show-first-last-page': true,
      },
      mostarDocumento: false,
      mostarFormula: false,
      mostarAutorizacion: false,
      mostrarComprobante: false,
      modalTitulo: '',
      pdfBase64: null,
      itemActual: null,
      mostrarBotones: false,
      documentos: {
        documento: null,
        formulaMedica: null,
        autorizacionMedica: null,
        comprobante: null,
        documentoFaltante: null,
      },
      documentosTemporales: {
        documento: null,
        formulaMedica: null,
        autorizacionMedica: null,
        comprobante: null,
        documentoFaltante: null,
      },
      formatoIncorrecto: {
        documento: '',
        formulaMedica: '',
        autorizacionMedica: '',
        comprobante: '',
        documentoFaltante: '',
      },
      mostrarModal: false,
      dialogTitulo: '',
      documentoSeleccionado: null,
      tipoDocumentoActual: '',
      documentosRequeridos: '',
      sinDocumentosRequeridos: false,
      solicitudSeleccionada: {},
      componenteDocumento: null,
      mensajeReemplazo: '',
      alertaReemplazo: false,
      dialogoRechazado: false,
      iR: null,
      mostrarSolicitudes: false,
      estadoRegistro: null,
      tiposVias: ["AVENIDA CALLE", "AVENIDA CARRERA", "AUTOPISTA", "AVENIDA", "ANILLO VIAL", "CALLE", "CALLEJÓN", "CARRERA", "CARRETERA", "CIRCUNVALAR", "DIAGONAL", "TRANSVERSAL"],
      screenSmaller: false,
      sizeText: null,
      validateProgress: false,
      mostrarNotificacion: false,
      mensajeNotificacion: "",
      tipoNotificacion: "",
      tipoNotificacionColor: "",
      iconoNotificacion: "",
      iconoNotificacionClase: "",
      documentoReemplazado: false,
      adjuntoPdf: null,
      dialogoAdjuntoNotificacion: false,
      extension: '',
      dialogMensajeObservacion: false,
      mensajeObservacion: '',
    }
  },

  // Se inicializa la variable userRoles con los roles del usuario autenticado
  created() {
    this.userRoles = this.auth.roles;
    this.iR = this.$store.state.idRegistro;
    this.listarSolicitudes();
  },

  computed: {
    ...mapState(["auth", "enterprise", "idRegistro" ]),
    // Calcula el índice del primer elemento de la página actual
    inicioItem() {
      return (this.pagina - 1) * this.itemsPorPagina + 1;
    },
    // Calcula el índice del último elemento de la página actual
    finItem() {
      const fin = this.pagina * this.itemsPorPagina;
      return fin > this.totalPaginas ? this.totalPaginas : fin;
    },
  },

  watch: {
  },

  mounted() {
    this.usuario = this.auth.username.toUpperCase();
    window.setTimeout(function () {
      $(".left_resizable").resizable({
        resize: function (event, ui) {
          ui.size.width = ui.originalSize.width;
        },
      });
    }, 1500);
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },

  methods: {
    ...mapMutations([]),
    /**
     * Navega a la primera página del historial de solicitudes de entrega y actualiza la lista de solicitudes.
     */
    irPrimeraPagina() {
      this.pagina = 1;
      this.listarSolicitudes();
    },
    /**
     * Navega a la página anterior del historial de solicitudes de entrega, si es posible, y actualiza la lista de solicitudes.
     */
    anteriorPagina() {
      if (this.pagina > 1) {
        this.pagina--;
        this.listarSolicitudes();
      }
    },
    /**
     * Navega a la siguiente página del historial de solicitudes de entrega, si es posible, y actualiza la lista de solicitudes.
     */
    siguientePagina() {
      if (this.pagina * this.itemsPorPagina < this.totalPaginas) {
        this.pagina++;
        this.listarSolicitudes();
      }
    },
    /**
     * Navega a la última página del historial de solicitudes de entrega y actualiza la lista de solicitudes.
     */
    irUltimaPagina() {
      this.pagina = Math.ceil(this.totalPaginas / this.itemsPorPagina);
      this.listarSolicitudes();
    },
    /**
     * Cambia la cantidad de ítems por página y actualiza la lista de solicitudes.
     */
    cambiarItemsPorPagina() {
      this.listarSolicitudes();
    },
    /**
     * Muestra un mensaje de error cuando el formato del archivo es incorrecto.
     */
    mostrarMensajeErrorFormatoIncorrecto(type) {
      this.formatoIncorrecto[type] = 'Formato incorrecto, debe subir un archivo pdf o una imagen .png, .jpg, .jpeg, .tiff';
    },
    /**
     * Carga el documento faltante.
     * 
     * @param {string} type - El tipo de documento que se está cargando.
     */
    cargarDocumentoFaltante(type, item) {
      this.solicitudSeleccionada = item;
      const fileInput = event.target;

      if (fileInput && fileInput.files && fileInput.files[0]) {
        const file = fileInput.files[0];

        const extensionesPermitidas = ['pdf', 'jpg', 'png', 'jpeg', 'tiff'];

        if (!file || !extensionesPermitidas.includes(file.name.split('.').pop().toLowerCase())) {
          this.mostrarMensajeErrorFormatoIncorrecto(type);
          return;
        }
      
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;
          if (this.esImagen(base64String) || this.esPDF(base64String)) {
            this.documentosTemporales[type] = reader.result;
            this.tipoDocumentoActual = type;
            this.visibility = false;
            this.mostrarDocumento(type);
            this.formatoIncorrecto[type] = false;
          } else {
            this.mostrarMensajeErrorFormatoIncorrecto(type);
          }
        };
        reader.readAsDataURL(file);
        fileInput.value = null;
      }
    },
    /**
     * Carga un documento de tipo especificado.
     * 
     * @param {string} type - El tipo de documento que se está cargando.
     */
    cargarDocumento(type) {
      const fileInput = event.target;

      if (fileInput && fileInput.files && fileInput.files[0]) {
        const file = fileInput.files[0];

        const extensionesPermitidas = ['pdf', 'jpg', 'png', 'jpeg', 'tiff'];

        if (!file || !extensionesPermitidas.includes(file.name.split('.').pop().toLowerCase())) {
          this.mostrarMensajeErrorFormatoIncorrecto(type);
          return;
        }
      
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;
          if (this.esImagen(base64String) || this.esPDF(base64String)) {
            this.documentosTemporales[type] = reader.result;
            this.tipoDocumentoActual = type;
            this.visibility = false;
            this.mostrarDocumento(type);
            this.formatoIncorrecto[type] = false;
          } else {
            this.mostrarMensajeErrorFormatoIncorrecto(type);
          }
        };
        reader.readAsDataURL(file);
        fileInput.value = null;
      }
    },
    /** Valida que la imagen sea correcta */
    esImagen(base64String) {
      return base64String.startsWith('data:image/jpeg;base64,/9j/') || 
        base64String.startsWith('data:image/jpg;base64,') ||
        base64String.startsWith('data:image/png;base64,iVBORw0KGgo') ||
        base64String.startsWith('data:image/tiff;base64,SUkq');
    },
    /** Valida que el pdf sea correcto */
    esPDF(base64String) {
      if (!base64String.startsWith('data:application/pdf;base64,')) {
        return false;
      }

      const binary = atob(base64String.split(',')[1]);
      const bytes = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        bytes[i] = binary.charCodeAt(i);
      }

      const pdfText = new TextDecoder().decode(bytes);

      return pdfText.includes('%PDF-');
    },
    /**
     * Confirma el documento seleccionado y realiza los cambios necesarios en la solicitud correspondiente.
     * 
     * Si no hay archivos faltantes, se procede a enviar la solicitud de cambio de documentos al servidor.
     * Si la solicitud fue exitosa, se muestra un mensaje de éxito y se actualiza la lista de solicitudes.
     * Si hay archivos faltantes, se muestra un mensaje.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    confirmarDocumento() {
      const tipoDocumento = this.tipoDocumentoActual;
      this.documentos[tipoDocumento] = this.documentosTemporales[tipoDocumento];
      let cambiarDocumento;
      if (tipoDocumento === 'documento') {
        this.solicitudSeleccionada.documentoCorrecto = true;
        this.solicitudSeleccionada.visibleCount --;
        cambiarDocumento = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento.replace(/^data:application\/pdf;base64,/, ''),
          },
          nombreFormula: this.documentos.formulaMedica !== null ? this.documentos.formulaMedica.replace(/^data:application\/pdf;base64,/, '') : '',
          nombreAutorizacion: this.documentos.autorizacionMedica !== null ? this.documentos.autorizacionMedica.replace(/^data:application\/pdf;base64,/, '') : this.documentos.documentoFaltante != null ? this.documentos.documentoFaltante.replace(/^data:application\/pdf;base64,/, '') : '',
          nombreComprobante: this.documentos.comprobante !== null ? this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, '') : '',
        };
      } else if (tipoDocumento === 'formulaMedica') {
        this.solicitudSeleccionada.formulaCorrecta = true;
        this.solicitudSeleccionada.visibleCount --;
        cambiarDocumento = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento !== null ? this.documentos.documento.replace(/^data:application\/pdf;base64,/, '') : '',
          },
          nombreFormula: this.documentos.formulaMedica.replace(/^data:application\/pdf;base64,/, ''),
          nombreAutorizacion: this.documentos.autorizacionMedica !== null ? this.documentos.autorizacionMedica.replace(/^data:application\/pdf;base64,/, '') : this.documentos.documentoFaltante != null ? this.documentos.documentoFaltante.replace(/^data:application\/pdf;base64,/, '') : '',
          nombreComprobante: this.documentos.comprobante !== null ? this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, '') : '',
        };
      } else if (tipoDocumento === 'autorizacionMedica') {
        this.solicitudSeleccionada.autorizacionCorrecta = true;
        this.solicitudSeleccionada.visibleCount --;
        cambiarDocumento = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento !== null ? this.documentos.documento.replace(/^data:application\/pdf;base64,/, '') : '',
          },
          nombreFormula: this.documentos.formulaMedica !== null ? this.documentos.formulaMedica.replace(/^data:application\/pdf;base64,/, '') : '',
          nombreAutorizacion: this.documentos.autorizacionMedica.replace(/^data:application\/pdf;base64,/, ''),
          nombreComprobante: this.documentos.comprobante !== null ? this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, '') : '',
        };
      } else if (tipoDocumento === 'comprobante') {
        this.solicitudSeleccionada.comprobantePago = true;
        this.solicitudSeleccionada.visibleCount --;
        cambiarDocumento = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento !== null ? this.documentos.documento.replace(/^data:application\/pdf;base64,/, '') : '',
          },
          nombreFormula: this.documentos.formulaMedica !== null ? this.documentos.formulaMedica.replace(/^data:application\/pdf;base64,/, '') : '',
          nombreAutorizacion: this.documentos.autorizacionMedica !== null ? this.documentos.autorizacionMedica.replace(/^data:application\/pdf;base64,/, '') : this.documentos.documentoFaltante != null ? this.documentos.documentoFaltante.replace(/^data:application\/pdf;base64,/, '') : '',
          nombreComprobante: this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, ''),
        };
      } else if (tipoDocumento === 'documentoFaltante') {
        cambiarDocumento = {
          registroAfiliado : {
            documentoDigital: this.documentos.documento!== null ? this.documentos.documento.replace(/^data:application\/pdf;base64,/, '') : '',
          },
          nombreFormula: this.documentos.formulaMedica!== null ? this.documentos.formulaMedica.replace(/^data:application\/pdf;base64,/, '') : '',
          nombreAutorizacion: this.documentos.documentoFaltante.replace(/^data:application\/pdf;base64,/, ''),
          nombreComprobante: this.documentos.comprobante!== null ? this.documentos.comprobante.replace(/^data:application\/pdf;base64,/, '') : '',
        };
      } 
      cambiarDocumento = {
        idSolicitud: this.solicitudSeleccionada.idSolicitud,
        direccionEntrega: this.solicitudSeleccionada.direccionEntrega,
        ...cambiarDocumento,
      }
      const tieneVisibilidadCero = this.solicitudSeleccionada.visibleCount === 0 || this.solicitudSeleccionada.visibleCount === undefined;
      const esMotivoRechazo18YDocumentoFaltante = this.solicitudSeleccionada.idMotivo === 18 && this.documentos.documentoFaltante !== null;

      if ((tieneVisibilidadCero && esMotivoRechazo18YDocumentoFaltante) || (tieneVisibilidadCero && this.solicitudSeleccionada.idMotivo !== 18)) {
        this.validateProgress = true;
        this.$http.post(`msa-external/api/solicitudEntrega/editar-documentos`, cambiarDocumento)
        .then((response) => {
          if (response.status === 200) {
            this.mensajeReemplazo = "La solicitud ha sido reenviada exitosamente.";
            this.alertaReemplazo = true;
            this.listarSolicitudes();
            this.reset();
            this.cerrarDocumento();
            this.solicitudSeleccionada = {};
            setTimeout(() => {
              this.alertaReemplazo = false;
              this.mensajeReemplazo = '';
            }, 3000);
          }
          this.validateProgress = false;
        }).catch((error) => {
          this.validateProgress = false;
          this.observarError(error);
        })
      } else {
        this.mensajeReemplazo = "Debe reemplazar el archivo faltante.";
        this.alertaReemplazo = true;
        this.mostrarModal = false;
        this.mostarDocumento = false;
        this.mostarFormula = false;
        this.mostarAutorizacion = false;
        this.mostrarComprobante = false;
        this.pdfBase64 = null;
        this.modalTitulo = '';
        setTimeout(() => {
          this.alertaReemplazo = false;
          this.mensajeReemplazo = '';
        }, 3000);
      }
    },
    /**
     * Abre el modal para mostrar el documento correspondiente.
     * 
     * @param {string} tipoDocumento - Tipo de documento a mostrar ('documentoFaltante').
     */
    abrirDocumento(tipoDocumento) {
      this.visibility = true;
      this.mostrarDocumento(tipoDocumento);
    },
    /**
     * Borra el documento seleccionado del tipo especificado.
     * 
     * @param {string} type - Tipo de documento a borrar ('documento', 'formulaMedica', 'autorizacionMedica', 'cuotaModerada', 'pagoDomicilio').
     */
    borrarDocumento(type) {
      this.documentos[type] = null;
    },
    /**
     * Borra el documento seleccionado del tipo especificado por medio del icono 'delete'.
     * 
     * @param {string} type - Tipo de documento a borrar ('documentoFaltante').
     */
    borrarDocumentoIcono(type) {
      const tipoDocumento = type;
      const refDocumentoFaltante = this.$refs.documentoFaltanteFile;

      if (tipoDocumento === 'documentoFaltante' && refDocumentoFaltante) {
        refDocumentoFaltante[0].reset();
        this.documentos.documentoFaltante = null;
      }
    },
    /**
     * Cierra el modal de visualización de documento y restablece los valores relacionados.
     * Reinicia los campos de carga de archivo según el tipo de documento y restablece el documento temporal y el tipo de documento actual.
     */
    cerrarModal() {
      const tipoDocumento = this.tipoDocumentoActual;
      this.documentosTemporales[tipoDocumento] = null;
      this.tipoDocumentoActual = '';
      const refDocumento = this.$refs.documentoIdentidadFile;
      const refFormulaMedica = this.$refs.formulaMedicaFile;
      const refAutorizacionMedica = this.$refs.autorizacionMedicaFile;
      const refComprobante = this.$refs.comprobanteFile;
      const refFaltante = this.$refs.documentoFaltanteFile;

      if (tipoDocumento === 'documento' && refDocumento) {
        refDocumento.reset();
        this.documentos.documento = null;
      } else if (tipoDocumento === 'formulaMedica' && refFormulaMedica) {
        refFormulaMedica.reset();
        this.documentos.formulaMedica = null;
      } else if (tipoDocumento === 'autorizacionMedica' && refAutorizacionMedica) {
        refAutorizacionMedica.reset();
        this.documentos.autorizacionMedica = null;
      } else if (tipoDocumento === 'comprobante' && refComprobante) {
        refComprobante.reset();
        this.documentos.comprobante = null;
      } else if (tipoDocumento == 'documentoFaltante' && refFaltante) {
        refFaltante[0].reset();
        this.documentos.documentoFaltante = null;
      }
      this.mostrarModal = false;
    },
    /**
     * Muestra el documento seleccionado para su visualización en el modal correspondiente.
     * 
     * @param {string} type - El tipo de documento a mostrar.
     */
    mostrarDocumento(type) {
      this.tipoDocumentoActual = type;
      this.dialogTitulo = `Verifique que el nuevo documento sea legible.`;
      if (this.documentosTemporales[type] != null) {
        this.documentoSeleccionado = this.documentosTemporales[type];
        const extension = this.documentosTemporales[type].split(';')[0].split('/')[1];
        this.extension = extension;
        if (extension === 'pdf') {
          this.componenteDocumento = 'pdf';
        } else if (['jpg', 'png', 'jpeg', 'tiff'].includes(extension)) {
          this.componenteDocumento = 'imagen';
          if (extension === 'tiff') {
            this.$nextTick(() => this.renderTiff());
          }
        } else {
          this.componenteDocumento = null;
        }
      } else {
        this.documentoSeleccionado = this.documentos[type];
      }
      this.mostrarModal = true;
    },
    /**
     * Renderiza un archivo TIFF en un canvas.
     */
    renderTiff() {
      const canvas = this.$refs.tiffCanvas;
      const tiff = new Tiff({ buffer: this.dataURItoBuffer(this.documentoSeleccionado) });
      const context = canvas.getContext('2d');
      canvas.width = tiff.width();
      canvas.height = tiff.height();
      context.drawImage(tiff.toCanvas(), 0, 0);
    },
    /**
     * Convierte un Data URI a un ArrayBuffer.
     * 
     * @param dataURI - El Data URI que representa la imagen en formato base64.
     * @returns {Uint8Array} - Un array de bytes que representa la imagen.
     */
    dataURItoBuffer(dataURI) {
      const base64 = dataURI.split(',')[1];
      const binary = atob(base64);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Uint8Array(array);
    },
    /**
     * Realiza una solicitud al servidor para obtener el historial de solicitudes de entrega del afiliado.
     * Se muestra el historial de solicitudes y se realiza el procesamiento de documentos y bodegas para cada solicitud.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    listarSolicitudes() {
      this.loading = true;
      const idAfiliado = this.iR;
      this.$http.get(`msa-external/api/solicitudEntrega/historial`, {
        params: {
          idAfiliado: idAfiliado,
          page: this.pagina - 1,
          size: this.itemsPorPagina,
        },
      }).then(response => {
        if (response.data.body.content && response.data.body.content.length > 0) {
          this.listaDeSolicitudes = response.data.body.content.sort((a, b) => b.idSolicitud - a.idSolicitud);
          this.totalPaginas = response.data.body.totalElements;
          this.mostrarSolicitudes = true;
          this.listaDeSolicitudes.forEach(item => {
            this.documentosACorregir(item);
            this.motivosRechazo(item);
          });
          this.listaDeSolicitudes.forEach(item => {
            this.bodegasSolicitud(item);
          });
        } else {
          this.$http.get(`msa-external/api/solicitud-temporal/historial`, {
            params: {
              idAfiliado: idAfiliado,
              page: this.pagina - 1,
              size: this.itemsPorPagina,
            },
          }).then(response => {
            if (response.data.body.content && response.data.body.content.length > 0) {
              this.listaDeSolicitudes = response.data.body.content.sort((a, b) => b.idSolicitud - a.idSolicitud);
              this.totalPaginas = response.data.body.totalElements;
              this.mostrarSolicitudes = true;
              this.listaDeSolicitudes.forEach(item => {
                this.documentosACorregir(item);
              });
              this.listaDeSolicitudes.forEach(item => {
                this.bodegasSolicitud(item);
              });
            } else {
              this.mostrarSolicitudes = false;
            }
          }).catch(error => {
            this.observarError(error);
            this.loading = false;
          })
        }
      }).catch(error => {
        this.observarError(error);
        this.loading = false;
      })
    },
    /**
     * Procesa los documentos para una solicitud específica y cuenta cuántos documentos incorrectos hay.
     */
    documentosACorregir(item) {
      this.estadoRegistro = item.registroAfiliado.estadoRegistro;
      if (item.estadoSolicitudEntrega === 'REFUSED') {
        const visibleCount = [
          { value: item.registroAfiliado.documentoDigital, correct: !item.documentoCorrecto },
          { value: item.nombreFormula, correct: !item.formulaCorrecta },
          { value: item.nombreAutorizacion, correct: !item.autorizacionCorrecta },
          { value: item.nombreComprobante, correct: !item.comprobantePago }
        ].filter(td => this.docIncorrecto(td)).length;
        this.$set(item, 'visibleCount', visibleCount);
        return visibleCount === 4;
      }
    },
    /**
     * Verifica si un documento es incorrecto.
     */
    docIncorrecto(td) {
      return td.value !== '' && td.value !== null && td.correct;
    },
    /**
     * Obtiene información sobre la bodega relacionada con una solicitud de entrega programada.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    bodegasSolicitud(item) {
      if (item.estadoSolicitudEntrega === 'RESOLVED' && item.tipoSolicitud === "SCHEDULED_DELIVERY" && item.bodegas !== null && item.bodegas.bodegasId.codigoBodega !== 0 ) {
        this.$http.get(`msa-external/api/bodegas/1/${item.bodegas.bodegasId.codigoBodega}`)
        .then((response) => {
          const bodegas = response.data;
          this.$set(item, 'bodegaConsultada', bodegas.nombreBodega);
          this.$set(item, 'direccionBodega', bodegas.direccion);
        }).catch(error => {
          this.observarError(error);
        })
      }
    },
    /**
     * Consulta los motivos de rechazo de las solicitudes en estado novedad y en estado rechazadas.
     * Almacena los motivos en variables internas dentro de cada solicitud para permitir su visualización.
     * @param item - La solicitud seleccionada.
     */
    motivosRechazo(item) {
      if (item.estadoSolicitudEntrega === 'NOVELTY') {
        this.$http.get(`msa-external/api/motivo-solicitud/${item.idSolicitud}/${'R'}`)
        .then((response) => {
          this.$set(item, 'descripcionMotivo', response.data[0].descripcionMotivo);
          this.$set(item, 'adjunto', response.data[0].adjunto);
          this.$set(item, 'idMotivo', response.data[0].idMotivo);
        }).catch(error => {
          this.observarError(error);
        })
      } else if (item.estadoSolicitudEntrega === 'REFUSED' || item.estadoSolicitudEntrega === 'CLOSE') {
        this.$http.get(`msa-external/api/motivo-solicitud/${item.idSolicitud}/${'I'}`)
        .then((response) => {
          let descripcionMotivo = '';
          let adjunto = '';
          let idMotivo = '';
          let observacion = null;
          response.data.forEach((motivo, index) => {
            descripcionMotivo += motivo.descripcionMotivo;
            if (index < response.data.length - 1) {
              descripcionMotivo += ', ';
            }
            if (!adjunto && motivo.adjunto) {
              adjunto = motivo.adjunto;
            }
            if (motivo.idMotivo === 18) {
              idMotivo = 18;
            }
            if (motivo.observacion && motivo.observacion.trim() !== '') {
              observacion = motivo.observacion;
            }
          });
          this.$set(item, 'descripcionMotivo', descripcionMotivo);
          this.$set(item, 'adjunto', adjunto);
          this.$set(item, 'idMotivo', idMotivo);
          this.$set(item, 'observacionMotivo', observacion);
        }).catch(error => {
          this.observarError(error);
        })
      }
    },
    /**
     * Formatea la fecha en el formato 'DD-MM-YYYY h:mm a'.
     * 
     * @param {Date} fecha - La fecha a formatear.
     * @returns {string} La fecha formateada.
     */
    formatearFecha(fecha) {
      return moment(fecha).format('DD-MM-YYYY h:mm a');
    },
    /**
     * Traduce el estado de la solicitud al español.
     * 
     * @param {string} estado - El estado de la solicitud.
     * @returns {string} El estado traducido o el estado original si no se encuentra una traducción.
     */
    traducirEstado(estado) {
      const traducciones = {
        'CREATE': 'Creada',
        'VALIDATED': 'Validada',
        'INVOICED': 'Facturada',
        'DISPATCHED': 'Despachada',
        'RESOLVED': 'Resuelta',
        'REFUSED': 'Rechazada',
        'CLOSE': 'Cerrada',
        'OPEN': 'Abierta',
        'NOVELTY': 'Novedad',
      };
      return traducciones[estado] || estado;
    },
    /**
     * Muestra el documento de identidad asociado a la solicitud.
     * 
     * @param {Object} item - La solicitud seleccionada.
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    verDocumento(item) {
      this.solicitudSeleccionada = item;
      this.documentoReemplazado = false;
      if (this.documentos.documento !== null) {
        this.documentoReemplazado = true;
        this.mostrarDocumento('documento');
      } else {
        this.documentoReemplazado = false;
        this.pdfBase64 = null;
        this.modalTitulo = '';
        this.itemActual = item;
        const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/getFileBase64' : 'solicitud-temporal/getFileBase64';
        const rutaDocumento = `${item.registroAfiliado.idRegistro}/${item.registroAfiliado.documentoDigital}`;
        this.$http.get(`msa-external/api/${endpoint}?fileName=${rutaDocumento}`)
        .then( response => {
          this.modalTitulo = `Documento de identidad`;
          this.mostarDocumento = true;
          this.pdfBase64 = "data:application/pdf;base64," + response.data;
          if (item.documentoCorrecto === false && item.estadoSolicitudEntrega === 'REFUSED') {
            this.mostrarBotones = true;
          } else {
            this.mostrarBotones = false;
          }
        }).catch(error => {
          this.observarError(error);
        })
      }
    },
    /**
     * Muestra la fórmula médica asociada a la solicitud.
     * 
     * @param {Object} item - La solicitud seleccionada.
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    verFormula(item) {
      this.solicitudSeleccionada = item;
      this.documentoReemplazado = false;
      if (this.documentos.formulaMedica !== null) {
        this.documentoReemplazado = true;
        this.mostrarDocumento('formulaMedica');
      } else {
        this.documentoReemplazado = false;
        this.pdfBase64 = null;
        this.modalTitulo = '';
        this.itemActual = item;
        const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/getFileBase64' : 'solicitud-temporal/getFileBase64';
        const idSoli = this.estadoRegistro === true ? `${item.idSolicitud}` : `${item.idSote}`;
        const rutaDocumento = `${item.registroAfiliado.idRegistro}/${idSoli}/${item.nombreFormula}`;
        this.$http.get(`msa-external/api/${endpoint}?fileName=${rutaDocumento}`)
        .then( response => {
          this.modalTitulo = `Fórmula médica`;
          this.mostarFormula = true;
          this.pdfBase64 = "data:application/pdf;base64," + response.data;
          if (item.formulaCorrecta === false && item.estadoSolicitudEntrega === 'REFUSED') {
            this.mostrarBotones = true;
          } else {
            this.mostrarBotones = false;
          }
        }).catch(error => {
          this.observarError(error);
        })
      }
    },
    /**
     * Muestra la autorización médica asociada a la solicitud.
     * 
     * @param {Object} item - La solicitud seleccionada.
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    verAutorizacion(item) {
      this.solicitudSeleccionada = item;
      this.documentoReemplazado = false;
      if (this.documentos.autorizacionMedica !== null) {
        this.documentoReemplazado = true;
        this.mostrarDocumento('autorizacionMedica');
      } else {
        this.documentoReemplazado = false;
        this.pdfBase64 = null;
        this.modalTitulo = '';
        this.itemActual = item;
        const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/getFileBase64' : 'solicitud-temporal/getFileBase64';
        const idSoli = this.estadoRegistro === true ? `${item.idSolicitud}` : `${item.idSote}`;
        const rutaDocumento = `${item.registroAfiliado.idRegistro}/${idSoli}/${item.nombreAutorizacion}`;
        this.$http.get(`msa-external/api/${endpoint}?fileName=${rutaDocumento}`)
        .then( response => {
          this.modalTitulo = `Autorización médica`;
          this.mostarAutorizacion = true;
          this.pdfBase64 = "data:application/pdf;base64," + response.data;
          if (item.autorizacionCorrecta === false && item.estadoSolicitudEntrega === 'REFUSED') {
            this.mostrarBotones = true;
          } else {
            this.mostrarBotones = false;
          }
        }).catch(error => {
          this.observarError(error);
        })
      }
    },
    /**
     * Muestra el comprobante de pago asociada a la solicitud.
     * 
     * @param {Object} item - La solicitud seleccionada.
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    verComprobantePago(item) {
      this.solicitudSeleccionada = item;
      this.documentoReemplazado = false;
      if (this.documentos.comprobante !== null) {
        this.documentoReemplazado = true;
        this.mostrarDocumento('comprobante');
      } else {
        this.documentoReemplazado = false;
        this.pdfBase64 = null;
        this.modalTitulo = '';
        this.itemActual = item;
        const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/getFileBase64' : 'solicitud-temporal/getFileBase64';
        const idSoli = this.estadoRegistro === true ? `${item.idSolicitud}` : `${item.idSote}`;
        const rutaDocumento = `${item.registroAfiliado.idRegistro}/${idSoli}/${item.nombreComprobante}`;
        this.$http.get(`msa-external/api/${endpoint}?fileName=${rutaDocumento}`)
        .then( response => {
          this.modalTitulo = `Comprobande de pago`;
          this.mostrarComprobante = true;
          this.pdfBase64 = "data:application/pdf;base64," + response.data;
          if (item.comprobantePago === false && item.estadoSolicitudEntrega === 'REFUSED') {
            this.mostrarBotones = true;
          } else {
            this.mostrarBotones = false;
          }
        }).catch(error => {
          this.observarError(error);
        })
      }
    },
    /**
     * Permite visualizar el adjunto de la notificación.
     * 
     * @param item - La solicitud seleccionada.
     */
    verAdjuntoNotificacion(item) {
      this.adjuntoPdf = null;
      const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/getFileBase64' : 'solicitud-temporal/getFileBase64';
      const idSoli = this.estadoRegistro === true ? `${item.idSolicitud}` : `${item.idSote}`;
      const rutaDocumento = `${item.registroAfiliado.idRegistro}/${idSoli}/${'Adjuntos'}/${item.adjunto}`;
      this.$http.get(`msa-external/api/${endpoint}?fileName=${rutaDocumento}`)
      .then( response => {
        this.dialogoAdjuntoNotificacion = true;
        this.adjuntoPdf = "data:application/pdf;base64," + response.data;
      }).catch(error => {
        this.observarError(error);
      })
    },
    /**
     * Cierra la vista del archivo de la notificación
     */
    cerrarAdjunto(){
      this.dialogoAdjuntoNotificacion = false;
      setTimeout(() => {
        this.adjuntoPdf = null;
      }, 300)
    },
    /**
     * Descarga el archivo de la notificación y cierra la vista previa del archivo
     */
    descargarAdjunto() {
      const base64Pdf = this.adjuntoPdf.split(',')[1];
      const byteCharacters = atob(base64Pdf);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const link = document.createElement('a');
      const today = new Date();
      const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
      const fileName = `Notificación-${formattedDate}.pdf`;

      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(link.href);
      this.cerrarAdjunto();
    },
    /**
     * Restablece los valores relacionados con los documentos
     */
    reset() {
      this.documentos.documento = null;
      this.documentos.formulaMedica = null;
      this.documentos.autorizacionMedica = null;
      this.documentos.comprobante = null;
      this.documentos.documentoFaltante = null;
      this.documentosTemporales.documento = null;
      this.documentosTemporales.formulaMedica = null;
      this.documentosTemporales.autorizacionMedica = null;
      this.documentosTemporales.comprobante = null;
      this.documentosTemporales.documentoFaltante = null;
      this.formatoIncorrecto.documento = '';
      this.formatoIncorrecto.formulaMedica = '';
      this.formatoIncorrecto.autorizacionMedica = '';
      this.formatoIncorrecto.comprobante = '';
      this.formatoIncorrecto.documentoFaltante = ''; 
      this.documentosRequeridos = '';
      this.sinDocumentosRequeridos = false;
    },
    /**
     * Restablece los valores relacionados con la visualización de los documentos
     * y los campos de entrada de archivos si existen.
     */
    cerrarDocumento() {
      this.mostrarModal = false;
      this.dialogTitulo = '';
      this.documentoSeleccionado = null;
      this.tipoDocumentoActual = '';
      this.solicitudSeleccionada = {};
      this.mostarDocumento = false;
      this.mostarFormula = false;
      this.mostarAutorizacion = false;
      this.mostrarComprobante = false;
      if (this.$refs.documentoIdentidadFile) {
        this.$refs.documentoIdentidadFile.reset();
      }
      if (this.$refs.formulaMedicaFile) {
        this.$refs.formulaMedicaFile.reset();
      }
      if (this.$refs.autorizacionMedicaFile) {
        this.$refs.autorizacionMedicaFile.reset();
      }
      if (this.$refs.comprobanteFile) {
        this.$refs.comprobanteFile.reset();
      }
    },
    /**
     * Maneja errores capturados durante solicitudes al servidor.
     * Si el error es una 'Network Error' o es una respuesta con estado 401, establece el estado de error en el almacenamiento.
     * Si el error no corresponde a los casos anteriores, se registra en la consola.
     */
    observarError(error) {
      if (error.message === 'Network Error') {
        this.$store.commit('setError', true);
      } else if (error.response && error.response.status === 401) {
        this.$store.commit('setError', true);
      } else {
        this.manejarError(error);
      }
    },
    /**
     * Valida el tipo de dirección "Urbana o Rural".
     * De acuerdo al tipo decide como mostrar la direción.
     * @param {*} item La solicitud a domicilio a validar.
     */
    consultarTipoDirecion(item) {
      const esDireccionUrbana = this.tiposVias.some(opcion => item.direccionEntrega.startsWith(opcion));
      if (esDireccionUrbana) {
        return `${item.direccionEntrega}, ${item.barrio !== null ? item.barrio.barrio + ', ' : ''} ${item.ciudad.ciudad}, ${item.departamento.departamento}`
      } else {
        return `${item.direccionEntrega}, ${item.ciudad.ciudad}, ${item.departamento.departamento}`
      } 
    },
    /**
     * Establece la propiedad en true cuando la pantalla tiene un ancho igual o menor a 959
     */
    checkScreenSize() {
      this.screenSmaller = window.innerWidth <= 1064;
      if (window.innerWidth <= 740) {
        this.sizeText = 5
      } else {
        this.sizeText = 25
      }
    },
    /**
     * Muestra una notificación global en el componente.
     *
     * @param {string} mensaje - Mensaje que se mostrará en la notificación.
     * @param {string} tipo - Tipo de la notificación (por defecto, "error").
     * @param {string} icono - Icono de la notificación (por defecto, "highlight_off").
     */
    mostrarNotificacionGlobal(mensaje, tipo, icono) {
      this.mostrarNotificacion = true;
      this.tipoNotificacion = tipo || "advertencia"; // Tipo predeterminado es "error"
      this.tipoNotificacionColor = this.obtenerColorNotificacion(this.tipoNotificacion);
      this.iconoNotificacion = icono || "highlight_off"; // Icono predeterminado es "highlight_off"
      this.mensajeNotificacion = mensaje;
      this.iconoNotificacionClase = this.obtenerClaseIcono(this.tipoNotificacion);

      // Cierra la notificación después de 5 segundos
      setTimeout(() => {
        this.cerrarNotificacion();
      }, 5000); 
    },
    /**
     * Cierra la notificación
     */
    cerrarNotificacion() {
      this.mostrarNotificacion = false;
      this.mensajeNotificacion = "";
      this.tipoNotificacion = "";
    },
    /**
     * Obtiene el color correspondiente al tipo de notificación.
     * 
     * @param {string} tipo - Tipo de notificación.
     * @returns {string} - Color de la notificación.
     */
    obtenerColorNotificacion(tipo) {
      switch (tipo) {
        case "advertencia":
          return "#f80505";
        default:
          return "#f80505"; 
      }
    },
    /**
     * Obtiene la clase de icono correspondiente al tipo de notificación.
     * 
     * @param {*} tipo  - Tipo de notificación.
     * @returns {string} - Clase de icono.
     */
    obtenerClaseIcono(tipo) {
      switch (tipo) {
        case "advertencia":
          return "advertencia-icon";
        default:
          return "advertencia-icon";
      }
    },
    /**
     * Maneja errores y muestra notificaciones correspondientes.
     * 
     * @param {*} error - Objeto de error.
     */
    manejarError(error) {
      if (error.response) {
        if (error.response.status === 503) {
          this.mostrarNotificacionGlobal("Error en la red, inténtalo más tarde.", "advertencia", "highlight_off" );
        } else {
          this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
        }
      } else {
        this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
      }
    },
    /**
     * Obtiene la clase CSS correspondiente a un estado específico para resaltar su apariencia.
     */
    obtenerClaseEstado(estado) {
      const clases = {
        'CREATE': 'blue--text font-weight-bold',
        'VALIDATED': 'green--text font-weight-bold',
        'INVOICED': 'cyan--text font-weight-bold',
        'DISPATCHED': 'cyan--text font-weight-bold',
        'RESOLVED': 'cyan--text font-weight-bold',
        'REFUSED': 'red--text font-weight-bold',
        'CLOSE': 'green--text font-weight-bold',
        'OPEN': 'orange--text font-weight-bold',
        'NOVELTY': 'red--text font-weight-bold',
      };
      return clases[estado] || '';
    },
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>

.pagination-container {
  position: fixed;
  bottom: 0.5rem;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.9);
  border-top: 1px solid rgba(0, 0, 0, 0.1); 
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; 
}
.fondoDialog {
background-color: #1867c0;
color: white;
}
.formulario-flex {
flex-wrap: nowrap;
}
@media (max-width: 700px) {
.formulario-flex {
  flex-wrap: wrap !important;
}
}
::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
::v-deep .elevation .v-data-footer {
  width: 100%;
}
::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}
.resultadoLista {
  max-height: 12rem;
  overflow-y: auto;
  z-index: 999;
  width: 100%;
}
::v-deep .file .v-input__slot {
  padding-left: 0.3rem !important;
} 
::v-deep .file .v-text-field__prefix {
  background-color: #0d48a1dc;
  border-radius: 3px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 0.3rem 0 0.3rem;
  margin-right: 0.3rem;
  color: #ffffff;
}
.boton-actualizar {
  background-color: #0d47a1 !important;
  color: #fff;
  border-radius: 18px;
}
.container {
  padding: 0px;
  margin: 0 0 0 0;
  max-width: none !important;
  width: 100% !important;
}
.encabezadoCard {
  margin: 0 !important;
}
.encabezadoCard .v-card {
  padding: 3px;
}
.pagination {
  margin-top: 0;
}
.pagination-text {
  font-size: 13px;
}
.pdfClose {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.pdfClose>p {
  width: 100%
}
.boxPdf {
  overflow: auto;
  height: 73vh;
}
.boxPdf>span {
  width: 100%;
}
.iconPdfIframe {
  zoom: 3;
}
.height-table {
  height: 70px;
}
.table {
  border: 1px solid rgb(205, 205, 205) !important;
}
table thead {
  display: block;
  width: 100%;
  background: #c7c4c4;
}
table tbody {
  display: block;
  width: 100%;
  overflow-y: overlay;
  overflow-x: auto;
}
table tbody tr,
table thead tr {
  width: 100%;
  display: table;
}
table tbody tr td,
table thead tr th {
  display: table-cell;
  padding: 1px 2px;
  overflow: hidden;
  border-right: 1px solid #d4d4d4;
  word-wrap: break-word !important;
  word-break: break-all !important;
}
.tableTrazabilidad tbody tr td,
table thead tr th {
  padding: 3px !important;
}
table tbody tr td {
  font-size: 10px !important;
}
table tbody tr td:nth-last-child(1),
table thead tr th:nth-last-child(1) {
  border-right: none !important;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}
.notificationValidation {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30vw;
}
.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
}
.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
  padding-top: 3px;
}
.advertencia-icon {
  color: #f80505;
}
.rotate-animation-notification {
  animation: rotate-notification 1s ease-in-out; 
}
@keyframes rotate-notification  {
    0% {
        transform: rotateX(180deg);
    }

    50% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(180deg);
    }
}
.rotate-animation {
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.size {
    font-size: xxx-large;
    font-weight: bold;
}
::v-deep .notificacionError .v-data-footer {
  padding-top: 10px;
  justify-content: end;
  border: none;
}
.ver-observacion {
  color: #0d47a1;
  cursor: pointer;
  font-size: 0.90rem;
  text-decoration: underline;
  padding: 0 !important;
  text-transform: lowercase;
}
</style>